import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
var CryptoJS = require("crypto-js");

const PaymentComponent = ({ url, height, width, params }) => {
    let base_url = url;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [apicalled, setApiCalled] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        console.log('fetchData called');
        setApiCalled(true);
        var requestID = generateGUID();
        console.log('Generated Request ID:', requestID);

        
        
        const dataToPost = {
            BillingAddress: {
              FullName: params.BillingAddress.FullName,
              Email: params.BillingAddress.Email,
              Phone: params.BillingAddress.Phone,
              AddressLine1: params.BillingAddress.AddressLine1,
              AddressLine2: params.BillingAddress.AddressLine2,
              City: params.BillingAddress.City,
              PostalCode: params.BillingAddress.PostalCode,
              StateIso: params.BillingAddress.StateIso,
              CountryIso: params.BillingAddress.CountryIso
            },
            Amount: params.Amount,
            Currency: params.Currency,
            Comment: params.Comment,
            SaveCard: params.SaveCard,
            //SaveCardId: params.SaveCardId,
            disp_lng: params.disp_lng,
            Order: params.Order,
            OrderDescription: params.OrderDescription,
            merchantID: params.merchantID,
            hash: params.hash,
            showTopLogo: params.showTopLogo,
            showBottomLogo: params.showBottomLogo,
            urlNotify: params.urlNotify,
            urlRedirect: params.urlRedirect

        };

        var dataToCompute = JSON.stringify(dataToPost)+requestID + params.hash;
        var sha256 = ComputeSha256Hash(dataToCompute);
        console.log('Computed SHA256 Hash:', sha256);
      
        //const encryptedContent = JSON.stringify(dataToPost);
        console.log('Data to post:', dataToPost);
      
        try {
            const headers = {
                "merchant-number": params.merchantID,
                "request-id": requestID,
                "api-version": "3.00",
                "Content-Type": "application/json",
                "signature": sha256,
            };
        
            console.log('Request headers:', headers);
        
            const response = await fetch(base_url + '/merchants/creditcard/GetPaymentPage', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(dataToPost)
            });
        
            const jsonData = await response.json();
            console.log('Response data:', jsonData);
        
            if (!jsonData.hasOwnProperty('Result')) {
                if (jsonData.includes("/" + requestID + ".html"))
                    setData(jsonData);
                else {
                    alert(jsonData);
                    setError(true);
                }
            } else {
                alert(jsonData.Result);
                setError(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setError(true);
        }

     
    };

    function generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    function ComputeSha256Hash(str) {
        var hash = CryptoJS.SHA256(str);
        return hash.toString(CryptoJS.enc.Base64);
    }

    let url_params = `?params=${btoa(JSON.stringify(params))}`;
    let iFrameHeight = height ? height : 950;
    let iFrameWidth = width ? width : 550;

    useEffect(() => {
        console.log('useEffect called');
        if (!base_url) {
            alert("URL can't be null");
            setError(true);
        }
        if (!apicalled) {
            if (params) {
                if (params.Amount == null) {
                    alert("Amount Field can't be null");
                    setError(true);
                } else if (params.Currency == null || params.Currency === "") {
                    alert("Currency Field can't be null");
                    setError(true);
                } else if (params.merchantID == null || params.merchantID === "") {
                    alert("Merchant-ID Field can't be null");
                    setError(true);
                } else if (params.hash == null || params.hash === "") {
                    alert("Hash Field can't be null");
                    setError(true);
                } else {
                    fetchData();
                }
            } else {
                alert("params Field Required");
                setError(true);
            }
        }
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            {data && !error ?
                <iframe title='mytitle' src={base_url + data + url_params} width={iFrameWidth} height={iFrameHeight} onLoad={() => setLoading(false)} hidden={loading}></iframe>
                :
                null
            }
            {error ?
                <div >
                    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: iFrameWidth, height: iFrameHeight, backgroundColor: 'rgba(0,0,0,1)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <p style={{ fontSize: '50px', color: 'white', fontFamily: 'Arial, sans-serif' }}>Error Occurred</p>
                    </div>
                </div>
                : null
            }
            {loading && !error ?
                <div >
                    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: iFrameWidth, height: iFrameHeight, backgroundColor: 'rgba(0,0,0,1)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <p style={{ fontSize: '50px', color: 'white', fontFamily: 'Arial, sans-serif' }}>Processing...</p>
                    </div>
                </div>
                : null
            }
        </div>
    );
};

export default PaymentComponent;
