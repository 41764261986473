import React, { useState } from 'react';
import PaymentComponent from './PaymentComponent';
//import CryptoJS from 'crypto-js';

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    FullName: 'Asfa J',
    Email: 'asfa@gmail.com',
    Phone: '7987987987987',
    AddressLine1: 'Testerson Rd. 57',
    AddressLine2: '2nd entrance',
    City: 'New York',
    PostalCode: '12345',
    StateIso: 'NY',
    CountryIso: 'US',
    Amount: 10.37,
    Currency: 'EUR',
    Comment: 'Comment',
    language: 'en',
    SaveCard: false,
    ShowBottomLogo: false,
    ShowTopLogo: false,
    //SaveCardId: '628023',
    Order: 'XXXX',
    OrderDescription: 'Payment for test product',
    merchantID: '1010101',
    hash: 'W6Y53U2UW9',
    url: 'https://webapi.pbtgatewaystage.com', // Default URL
    redirectUrl: 'https://homehealthhive.com/',
    notificationUrl: 'https://homehealthhive.com/test/',
    height: 750, // Default height
    width: 500 // Default width
  });

  const [showPayment, setShowPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const dataToPost = {
    //   BillingAddress: {
    //     FullName: formData.FullName,
    //     Email: formData.Email,
    //     Phone: formData.Phone,
    //     AddressLine1: formData.AddressLine1,
    //     AddressLine2: formData.AddressLine2,
    //     City: formData.City,
    //     PostalCode: formData.PostalCode,
    //     StateIso: formData.StateIso,
    //     CountryIso: formData.CountryIso
    //   },
    //   Amount: formData.Amount,
    //   Currency: formData.Currency,
    //   Comment: formData.Comment,
    //   SaveCard: formData.SaveCard,
    //   //SaveCardId: formData.SaveCardId,
    //   disp_lng: formData.language,
    //   Order: formData.Order,
    //   OrderDescription: formData.OrderDescription,
    //   merchantID: formData.merchantID,
    //   hash: formData.hash,
    //   ShowTopLogo:formData.ShowTopLogo,
    //   ShowBottomLogo: formData.ShowBottomLogo,
    //   urlNotify: formData.notificationUrl,
    //   urlRedirect: formData.redirectUrl,

    // };

    //const encryptedContent = CryptoJS.AES.encrypt(JSON.stringify(dataToPost), 'secret_key').toString();

    setShowPayment(true);
  };

  return (
    <div>
      <h1>Payment Form</h1>
      {!showPayment ? (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Full Name:</label>
            <input
              type="text"
              name="FullName"
              value={formData.FullName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Phone:</label>
            <input
              type="tel"
              name="Phone"
              value={formData.Phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Address Line 1:</label>
            <input
              type="text"
              name="AddressLine1"
              value={formData.AddressLine1}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Address Line 2:</label>
            <input
              type="text"
              name="AddressLine2"
              value={formData.AddressLine2}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>City:</label>
            <input
              type="text"
              name="City"
              value={formData.City}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Postal Code:</label>
            <input
              type="text"
              name="PostalCode"
              value={formData.PostalCode}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>State ISO:</label>
            <input
              type="text"
              name="StateIso"
              value={formData.StateIso}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Country ISO:</label>
            <input
              type="text"
              name="CountryIso"
              value={formData.CountryIso}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Amount:</label>
            <input
              type="number"
              name="Amount"
              value={formData.Amount}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Currency:</label>
            <input
              type="text"
              name="Currency"
              value={formData.Currency}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Comment:</label>
            <input
              type="text"
              name="Comment"
              value={formData.Comment}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Order:</label>
          <input
              type="text"
              name="Order"
              value={formData.Order}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Merchant ID:</label>
            <input
              type="text"
              name="merchantID"
              value={formData.merchantID}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Hash:</label>
            <input
              type="text"
              name="hash"
              value={formData.hash}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Order Description:</label>
            <input
              type="text"
              name="OrderDescription"
              value={formData.OrderDescription}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Language:</label>
            <input
              type="text"
              name="language"
              value={formData.language}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Save Card:</label>
            <input
              type="checkbox"
              name="SaveCard"
              checked={formData.SaveCard}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Show top logo:</label>
            <input
              type="checkbox"
              name="ShowTopLogo"
              checked={formData.ShowTopLogo}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Show bottom logo:</label>
            <input
              type="checkbox"
              name="ShowBottomLogo"
              checked={formData.ShowBottomLogo}
              onChange={handleChange}
            />
          </div>
          {/* <div>
            <label>Save Card ID:</label>
            <input
              type="text"
              name="SaveCardId"
              value={formData.SaveCardId}
              onChange={handleChange}
            />
          </div> */}
          <div>
            <label>Payment URL:</label>
            <input
              type="text"
              name="url"
              value={formData.url}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Redirect URL:</label>
            <input
              type="text"
              name="redirectUrl"
              value={formData.redirectUrl}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Notification URL:</label>
            <input
              type="text"
              name="notificationUrl"
              value={formData.notificationUrl}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Height:</label>
            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Width:</label>
            <input
              type="number"
              name="width"
              value={formData.width}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      ) : (
        <PaymentComponent
          url={formData.url}
          height={formData.height}
          width={formData.width}
          params={{
            BillingAddress: {
              FullName: formData.FullName,
              Email: formData.Email,
              Phone: formData.Phone,
              AddressLine1: formData.AddressLine1,
              AddressLine2: formData.AddressLine2,
              City: formData.City,
              PostalCode: formData.PostalCode,
              StateIso: formData.StateIso,
              CountryIso: formData.CountryIso
            },
            Amount: formData.Amount,
            Currency: formData.Currency,
            Comment: formData.Comment,
            SaveCard: formData.SaveCard,
            //SaveCardId: formData.SaveCardId,
            disp_lng: formData.language,
            Order: formData.Order,
            OrderDescription: formData.OrderDescription,
            merchantID: formData.merchantID,
            hash: formData.hash,
            showTopLogo: formData.ShowTopLogo,
           showBottomLogo: formData.ShowBottomLogo,
           urlNotify: formData.notificationUrl,
           urlRedirect: formData.redirectUrl,
          }}
        />
      )}
    </div>
  );
};

export default PaymentForm;
